exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-agileservices-js": () => import("./../../../src/pages/agileservices.js" /* webpackChunkName: "component---src-pages-agileservices-js" */),
  "component---src-pages-angular-js": () => import("./../../../src/pages/angular.js" /* webpackChunkName: "component---src-pages-angular-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digitalservices-js": () => import("./../../../src/pages/digitalservices.js" /* webpackChunkName: "component---src-pages-digitalservices-js" */),
  "component---src-pages-enterpriseservices-js": () => import("./../../../src/pages/enterpriseservices.js" /* webpackChunkName: "component---src-pages-enterpriseservices-js" */),
  "component---src-pages-flutter-js": () => import("./../../../src/pages/flutter.js" /* webpackChunkName: "component---src-pages-flutter-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-hybridservices-js": () => import("./../../../src/pages/hybridservices.js" /* webpackChunkName: "component---src-pages-hybridservices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ionic-js": () => import("./../../../src/pages/ionic.js" /* webpackChunkName: "component---src-pages-ionic-js" */),
  "component---src-pages-javascript-js": () => import("./../../../src/pages/javascript.js" /* webpackChunkName: "component---src-pages-javascript-js" */),
  "component---src-pages-node-js": () => import("./../../../src/pages/node.js" /* webpackChunkName: "component---src-pages-node-js" */),
  "component---src-pages-nodeservices-js": () => import("./../../../src/pages/nodeservices.js" /* webpackChunkName: "component---src-pages-nodeservices-js" */),
  "component---src-pages-ourteam-js": () => import("./../../../src/pages/ourteam.js" /* webpackChunkName: "component---src-pages-ourteam-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-reactnative-js": () => import("./../../../src/pages/reactnative.js" /* webpackChunkName: "component---src-pages-reactnative-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-technologies-js": () => import("./../../../src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-uiux-js": () => import("./../../../src/pages/uiux.js" /* webpackChunkName: "component---src-pages-uiux-js" */),
  "component---src-pages-vue-js": () => import("./../../../src/pages/vue.js" /* webpackChunkName: "component---src-pages-vue-js" */),
  "component---src-pages-webservices-js": () => import("./../../../src/pages/webservices.js" /* webpackChunkName: "component---src-pages-webservices-js" */)
}

